exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ru-about-us-tsx": () => import("./../../../src/pages/ru/about-us.tsx" /* webpackChunkName: "component---src-pages-ru-about-us-tsx" */),
  "component---src-pages-ru-brand-promotion-tsx": () => import("./../../../src/pages/ru/brand-promotion.tsx" /* webpackChunkName: "component---src-pages-ru-brand-promotion-tsx" */),
  "component---src-pages-ru-branding-tsx": () => import("./../../../src/pages/ru/branding.tsx" /* webpackChunkName: "component---src-pages-ru-branding-tsx" */),
  "component---src-pages-ru-community-tsx": () => import("./../../../src/pages/ru/community.tsx" /* webpackChunkName: "component---src-pages-ru-community-tsx" */),
  "component---src-pages-ru-contacts-tsx": () => import("./../../../src/pages/ru/contacts.tsx" /* webpackChunkName: "component---src-pages-ru-contacts-tsx" */),
  "component---src-pages-ru-dance-tsx": () => import("./../../../src/pages/ru/dance.tsx" /* webpackChunkName: "component---src-pages-ru-dance-tsx" */),
  "component---src-pages-ru-education-tsx": () => import("./../../../src/pages/ru/education.tsx" /* webpackChunkName: "component---src-pages-ru-education-tsx" */),
  "component---src-pages-ru-events-tsx": () => import("./../../../src/pages/ru/events.tsx" /* webpackChunkName: "component---src-pages-ru-events-tsx" */),
  "component---src-pages-ru-index-tsx": () => import("./../../../src/pages/ru/index.tsx" /* webpackChunkName: "component---src-pages-ru-index-tsx" */),
  "component---src-pages-ru-investors-tsx": () => import("./../../../src/pages/ru/investors.tsx" /* webpackChunkName: "component---src-pages-ru-investors-tsx" */),
  "component---src-pages-ru-lead-generation-tsx": () => import("./../../../src/pages/ru/lead-generation.tsx" /* webpackChunkName: "component---src-pages-ru-lead-generation-tsx" */),
  "component---src-pages-ru-license-tsx": () => import("./../../../src/pages/ru/license.tsx" /* webpackChunkName: "component---src-pages-ru-license-tsx" */),
  "component---src-pages-ru-pricing-tsx": () => import("./../../../src/pages/ru/pricing.tsx" /* webpackChunkName: "component---src-pages-ru-pricing-tsx" */),
  "component---src-pages-ru-privacypolicy-tsx": () => import("./../../../src/pages/ru/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-ru-privacypolicy-tsx" */),
  "component---src-pages-ru-private-event-tsx": () => import("./../../../src/pages/ru/private-event.tsx" /* webpackChunkName: "component---src-pages-ru-private-event-tsx" */),
  "component---src-pages-ru-promo-tsx": () => import("./../../../src/pages/ru/promo.tsx" /* webpackChunkName: "component---src-pages-ru-promo-tsx" */),
  "component---src-pages-ru-sport-tsx": () => import("./../../../src/pages/ru/sport.tsx" /* webpackChunkName: "component---src-pages-ru-sport-tsx" */),
  "component---src-pages-ru-team-building-tsx": () => import("./../../../src/pages/ru/team-building.tsx" /* webpackChunkName: "component---src-pages-ru-team-building-tsx" */),
  "component---src-pages-ru-training-tsx": () => import("./../../../src/pages/ru/training.tsx" /* webpackChunkName: "component---src-pages-ru-training-tsx" */),
  "component---src-pages-ru-user-agreement-tsx": () => import("./../../../src/pages/ru/user-agreement.tsx" /* webpackChunkName: "component---src-pages-ru-user-agreement-tsx" */),
  "component---src-pages-us-ad-tsx": () => import("./../../../src/pages/us/ad.tsx" /* webpackChunkName: "component---src-pages-us-ad-tsx" */),
  "component---src-pages-us-brand-promotion-tsx": () => import("./../../../src/pages/us/brand-promotion.tsx" /* webpackChunkName: "component---src-pages-us-brand-promotion-tsx" */),
  "component---src-pages-us-branding-tsx": () => import("./../../../src/pages/us/branding.tsx" /* webpackChunkName: "component---src-pages-us-branding-tsx" */),
  "component---src-pages-us-contacts-tsx": () => import("./../../../src/pages/us/contacts.tsx" /* webpackChunkName: "component---src-pages-us-contacts-tsx" */),
  "component---src-pages-us-cookies-policy-tsx": () => import("./../../../src/pages/us/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-us-cookies-policy-tsx" */),
  "component---src-pages-us-dance-tsx": () => import("./../../../src/pages/us/dance.tsx" /* webpackChunkName: "component---src-pages-us-dance-tsx" */),
  "component---src-pages-us-education-tsx": () => import("./../../../src/pages/us/education.tsx" /* webpackChunkName: "component---src-pages-us-education-tsx" */),
  "component---src-pages-us-enterprise-tsx": () => import("./../../../src/pages/us/enterprise.tsx" /* webpackChunkName: "component---src-pages-us-enterprise-tsx" */),
  "component---src-pages-us-fundraising-tsx": () => import("./../../../src/pages/us/fundraising.tsx" /* webpackChunkName: "component---src-pages-us-fundraising-tsx" */),
  "component---src-pages-us-gdpr-tsx": () => import("./../../../src/pages/us/gdpr.tsx" /* webpackChunkName: "component---src-pages-us-gdpr-tsx" */),
  "component---src-pages-us-index-tsx": () => import("./../../../src/pages/us/index.tsx" /* webpackChunkName: "component---src-pages-us-index-tsx" */),
  "component---src-pages-us-investors-tsx": () => import("./../../../src/pages/us/investors.tsx" /* webpackChunkName: "component---src-pages-us-investors-tsx" */),
  "component---src-pages-us-lead-generation-tsx": () => import("./../../../src/pages/us/lead-generation.tsx" /* webpackChunkName: "component---src-pages-us-lead-generation-tsx" */),
  "component---src-pages-us-marketplace-tsx": () => import("./../../../src/pages/us/marketplace.tsx" /* webpackChunkName: "component---src-pages-us-marketplace-tsx" */),
  "component---src-pages-us-pricing-tsx": () => import("./../../../src/pages/us/pricing.tsx" /* webpackChunkName: "component---src-pages-us-pricing-tsx" */),
  "component---src-pages-us-privacypolicy-tsx": () => import("./../../../src/pages/us/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-us-privacypolicy-tsx" */),
  "component---src-pages-us-private-event-tsx": () => import("./../../../src/pages/us/private-event.tsx" /* webpackChunkName: "component---src-pages-us-private-event-tsx" */),
  "component---src-pages-us-ready-content-tsx": () => import("./../../../src/pages/us/ready-content.tsx" /* webpackChunkName: "component---src-pages-us-ready-content-tsx" */),
  "component---src-pages-us-sport-tsx": () => import("./../../../src/pages/us/sport.tsx" /* webpackChunkName: "component---src-pages-us-sport-tsx" */),
  "component---src-pages-us-team-building-tsx": () => import("./../../../src/pages/us/team-building.tsx" /* webpackChunkName: "component---src-pages-us-team-building-tsx" */),
  "component---src-pages-us-terms-and-conditions-tsx": () => import("./../../../src/pages/us/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-us-terms-and-conditions-tsx" */),
  "component---src-pages-us-training-tsx": () => import("./../../../src/pages/us/training.tsx" /* webpackChunkName: "component---src-pages-us-training-tsx" */)
}

